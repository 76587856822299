<template>
  <v-row
    :class="$vuetify.breakpoint.mdAndUp ? 'mb-4' : 'mb-10'"
    :justify="$vuetify.breakpoint.smAndDown ? 'center' : 'start'"
  >
    <v-col
      cols="12"
      sm="6"
      md="4"
      lg="3"
      v-for="item in items"
      :key="item.id"
      class="mb-5"
    >
      <chapter-card :chapter="item"></chapter-card>
    </v-col>
    <!-- INICIO Dialog para confirmar la eliminación de un evento -->
    <!-- <v-dialog v-model="deleteEventDialog" width="600">
      <v-card>
        <v-card-text>
          <v-row justify="center">
            <v-col cols="10" md="7" class="mt-8 d-flex justify-center">
              <v-img class="" src="@/assets/images/weChamber/logo-WC-main-nav-drawer.svg" max-width="220" height="auto" contain></v-img>
            </v-col>
          </v-row>
          <v-row>
          <v-col cols="12" class="mt-2">
            <p class="text-center font-weight-bold headline mb-0">
              Eliminar capítulo
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <p class="title text-center mb-0">¿Está seguro que quiere eliminar el capítulo seleccionado?</p>
            <p class="title text-center">Los datos del evento no podrán ser recuperados.</p>
          </v-col>
        </v-row>
        <v-row
          class="d-flex pb-2 mt-5"
          :class="
            $vuetify.breakpoint.smAndUp ? 'justify-end' : 'justify-center'
          "
        >
          <v-btn class="font-weight-bold black--text mx-2" color="grey lighten-3" rounded elevation="0" @click="deleteEventDialog = false">
            Cancelar
          </v-btn>
          <v-btn class="font-weight-bold black--text mx-2" color="accent" rounded elevation="0" @click="$_deleteEvent()">
            Aceptar
          </v-btn>
        </v-row>
        </v-card-text>
      </v-card>
    </v-dialog> -->
    <!-- FIN Dialog para confirmar la eliminación de un evento -->
  </v-row>
</template>

<script>
import chapterCard from "./ChapterCard";
import { mapMutations, mapActions } from "vuex";

export default {
  components: {
    chapterCard,
  },
  props: {
    items: { type: Array, required: true },
  },
  data() {
    return {};
  },
  methods: {
    ...mapMutations("notification", ["show"]),
    ...mapMutations("loader", ["loading", "loaded"]),
  }
};
</script>