<template>
  <v-dialog v-model="activator" width="700" @click:outside="closeDialog" persistent>
    <v-card
    :class="$vuetify.breakpoint.smAndDown ? 'pa-9' : 'pa-14'"
    style="border-radius: 0px"
    color="white"
    >
        <v-card-text class="px-0">
          <span
            :style="{
              color: '#35404c',
              fontWeight: 900,
              fontSize: '18px',
            }"
            >Lista de documentos</span
          >
          <v-divider
            class="mt-1"
            :style="{
              background: '#35404c',
              border: '1px solid #35404c',
            }"
          />
          <v-list>
            <v-list-item-group>
              <v-list-item
                dense
                v-for="document, i in getFilesArray"
                :key="i"
              >
                <v-list-item-content>
                  <span
                    :style="{
                      fontSize: '15px',
                      color: '#35404c',
                      fontWeight: '800',
                    }"
                    >{{ document.name }}</span
                  >
                </v-list-item-content>


                <v-list-item-action>
                  <div class="d-flex justify-space-around">
                    <v-btn
                    small
                    fab
                    :disabled="!document.value"
                    elevation="0"
                    @click="openVisualizer(document.formattedName, true)">
                      <v-icon>
                        mdi-eye-outline
                      </v-icon>
                    </v-btn>

                    <v-btn
                          small
                          fab
                          :disabled="!document.value"
                          elevation="0"
                          :href="document.value"
                        >
                          <v-icon>mdi-download-outline</v-icon>
                      </v-btn>
                  </div>

                  <v-dialog :ref="document.formattedName" persistent fullscreen hide-overlay>
                    <v-card height="100%">
                      <iframe
                            type="application/pdf"
                            :id="`frame_${document.formattedName}`"
                            :src="`https://drive.google.com/viewerng/viewer?embedded=true&url=${document.value}`"
                            style="width: 100%; height: 100%; border: none"
                      ></iframe>
                    </v-card>
                    <v-btn
                          v-if="$vuetify.breakpoint.smAndDown != true"
                          @click="openVisualizer(document.formattedName)"
                          bottom
                          right
                          absolute
                          fab
                          color="accent"
                          :style="{
                            position: 'absolute',
                            bottom: '50px',
                            right: '50px',
                          }"
                          ><v-icon>mdi-close</v-icon></v-btn
                        >
                        <v-btn v-else @click="openVisualizer(document.formattedName)" large bottom
                          ><v-icon>mdi-close</v-icon></v-btn
                        >
                  </v-dialog>
                </v-list-item-action>

                <!-- <v-list-item-action
                  class="d-flex flex-row justify-space-between"
                >
                  <v-dialog
                    fullscreen
                    hide-overlay
                    transition="dialog-bottom-transition"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-tooltip top :activator="`#button${i}`">
                        <span>Visualizar {{ document.name }}</span>
                      </v-tooltip>
                      <v-btn activator
                        :id="`button${i}`"
                        v-bind="attrs"
                        v-on="on"
                        small
                        :disabled="disablewatch(document.id)"
                        fab
                        @click="downloadInvoiceDocument(id, false, document.id)"
                        elevation="0"
                        :class="document.id=='reference'||'mr-2'"
                        class=" rounded-pill"
                      >
                        <v-icon>mdi-eye-outline</v-icon>
                      </v-btn>
                    </template>
                    <template v-slot:default="dialog">
                      <v-btn
                        v-if="$vuetify.breakpoint.smAndDown != true"
                        @click="dialog.value = false"
                        bottom
                        right
                        absolute
                        fab
                        color="accent"
                        :style="{
                          position: 'absolute',
                          bottom: '50px',
                          right: '50px',
                        }"
                        ><v-icon>mdi-close</v-icon></v-btn
                      >
                      <v-btn v-else @click="dialog.value = false" large bottom
                        ><v-icon>mdi-close</v-icon></v-btn
                      >
                      <iframe
                        type="application/pdf"
                        :id="`pdfViewer${i}`"
                        :src="pdf"
                        style="width: 100%; height: 100%; border: none"
                      ></iframe>
                    </template>
                  </v-dialog> 

                  <v-tooltip top v-if="document.id != 'reference'">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        fab
                        small
                        class="rounded-pill"
                        elevation="0"
                        :disabled="disablewatch(document.id)"
                        @click="downloadInvoiceDocument(id, true, document.id)"
                      >
                        <v-icon> mdi-download-outline </v-icon>
                      </v-btn>                        
                    </template>
                    <span>Descargar {{ document.title }}</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        fab
                        small
                        :disabled="unableResentEmail(document.id)"
                        class="rounded-pill ml-2"
                        elevation="0"
                        @click="document.dialog=true"
                      >
                      <v-icon> mdi-email-arrow-right-outline </v-icon>
                      <PersonalizedDialog
                        :style="{display: 'none'}"
                        :dialog="document.dialog"
                        @save="setEmail(id, document.id)"
                        @discard="document.dialog=false"
                      >
                            <template #alertName>
                              Confirmar reenvío de correo        
                            </template>
                            <template #alertContent>                       
                              <p class="title text-center">
                                ¿Está seguro que desea reenviar la {{document.title}}?
                              </p>
                            </template>
                            <template #button>
                              Enviar    
                            </template>
                      </PersonalizedDialog>
                      </v-btn>

                    </template>
                    <span>Reenviar {{ document.title }}</span>
                  </v-tooltip>
                </v-list-item-action> -->
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn
            @click="closeDialog"
            elevation="0"
            style="font-size: 18px; font-weight: 800"
            class="text-capitalize rounded-pill"
          >
            <v-icon color="#35404c">mdi-chevron-left</v-icon>
            <span style="color: #35404c" class="pr-2">Volver</span>
          </v-btn>
        </v-card-actions>
      </v-card>
  </v-dialog>  
</template>
<script>
export default {
  props: {
    activator:{
      type: Boolean,
      default: false
    },
    files: {
      type: Array, default: (() => [])
    }
  },
  computed: {
    getActivator(){
      return this.activator
    },
    getFilesArray(){
      return this.files
    }
  },
  methods:{
    closeDialog(){
      this.$emit('close')
    },
    downloadFile(file){
      this.$emit('download', file)
    },
    openVisualizer(ref, active = false){
      this.$refs[ref][0].isActive = active
    }
  },
}

</script>
