<template>
  <v-container fluid class="wLightGray event-container">
    <v-row no-gutter>
      <v-col cols="12">
        <memberCounterCardVue
          v-if="indicators.hasOwnProperty('active')"
          :indicatorsContent="indicators"
        />
      </v-col>

      <v-col cols="12">
        <v-tabs v-model="tab">
          <v-tab
            v-for="(item, i) in ['Capítulos', 'Formulario de documentos']"
            :key="i"
          >
            {{ item }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item class="mt-10">
            <v-row>
              <v-col cols="10" xs="9">
                <v-sheet elevation="2" class="rounded-xl d-flex align-center">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        color="accent"
                        @click="filterBranch"
                        elevation="0"
                        fab
                        class="rounded-tr-0 rounded-br-0"
                        small
                        v-on="on"
                      >
                        <v-icon size="20" :color="color.primaryVariant"
                          >fa fa-search</v-icon
                        >
                      </v-btn>
                    </template>
                    Clic para buscar un capítulo
                  </v-tooltip>
                  <v-text-field
                    v-model="filtroBranch"
                    solo
                    dense
                    flat
                    placeholder="Buscar capítulo"
                    hide-details="auto"
                    class="rounded-search"
                    clearable
                    background-color="#f9fafb"
                    v-on:keyup.enter="filterBranch()"
                    @click:clear="(eventsSearchData.name = ''), fetchBranches()"
                  >
                  </v-text-field>
                </v-sheet>
              </v-col>

              <v-col cols="2" xs="2" class="d-flex justify-end">
                <v-btn
                  to="/nuevo-capitulo"
                  color="accent"
                  :small="$vuetify.breakpoint.xsOnly"
                  :class="$vuetify.breakpoint.smAndUp ? 'rounded-xl' : ''"
                  :fab="$vuetify.breakpoint.smAndDown"
                  :disabled="
                    !user.permissions.Chapters ||
                    !user.permissions.Chapters.create
                  "
                >
                  <v-icon v-if="$vuetify.breakpoint.smAndDown">
                    mdi-plus
                  </v-icon>
                  <span v-else class="font-weight-bold black--text"
                    >+ Nuevo capítulo</span
                  >
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-if="loadingChapters" class="mb-15">
              <v-col cols="12">
                <p class="title font-weight-bold mb-0 text-center">
                  Cargando mis capitulos
                </p>
              </v-col>
              <v-col cols="12" offset-sm="3" sm="6">
                <v-progress-linear
                  color="primary"
                  indeterminate
                  rounded
                  height="6"
                ></v-progress-linear>
              </v-col>
            </v-row>
            <v-row
              v-else
              :class="$vuetify.breakpoint.smAndUp ? 'mx-5' : 'mx-1'"
            >
              <v-col cols="12">
                <chapter-card-list :items="branch"> </chapter-card-list>
              </v-col>
              <!--       <v-col v-if="organizationEventsPaginated.totalDocs == 0 && !organizationEventsPaginated.hasPrevPage" cols="12">
              <v-row justify="center">
                <v-col cols="12">
                  <p class="headline text-center font-weight-bold">
                    No se encontraron resultados
                  </p>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-img src="@/assets/images/general/no_data_events.svg" max-width="300"></v-img>
              </v-row>
            </v-col> -->
            </v-row>
          </v-tab-item>

          <v-tab-item>
            <ChapterDocuments></ChapterDocuments>
          </v-tab-item>
        </v-tabs-items>
      </v-col>

      <!-- ******************************************  Filtro deshabilitado  ********************************* -->
      <!-- <v-col cols="1" xs="1">
        <div class="text-center">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="white" dark v-bind="attrs" v-on="on">
                <v-icon color="#192533"> filter_alt </v-icon>
              </v-btn>
            </template>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Filtro 1 </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>Filtro 2</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-menu>
        </div>
      </v-col> -->

      <!-- <v-col cols="2" xs="2" class="d-flex justify-end">
        <v-btn
          :color="color.primaryVariant"
          :small="$vuetify.breakpoint.xsOnly"
          :class="$vuetify.breakpoint.smAndUp ? 'rounded-xl' : ''"
          :fab="$vuetify.breakpoint.smAndDown"
          :disabled="!user.permissions.Chapters || !user.permissions.Chapters.create"
        >
          <v-icon v-if="$vuetify.breakpoint.smAndDown"> mdi-plus </v-icon>
          <span v-else class="font-weight-bold white--text"
            >Gestionar Documentos</span
          >
        </v-btn>
      </v-col> -->
    </v-row>
    <!--Si ya no se están cargando los eventos habilitar el páginador mediante el scroll-->
    <!-- <v-row v-if="!loaderVisible">
      <v-col cols="12">
        <v-card v-intersect="fetchBranches"></v-card>
      </v-col>
    </v-row> -->
  </v-container>
</template>
<script>
import permits from "../../../views/dashboard/permits";
import chapterCardList from "@/components/dashboard/event/ChapterCardList";
import ChapterDocuments from "./ChapterDocuments.vue";
import { mapState, mapMutations, mapActions } from "vuex";
import { colors } from "@/constants/colors.js";
import memberCounterCardVue from "@/components/dashboard/event/analytics/member-counter-card.vue";
export default {
  components: {
    chapterCardList,
    memberCounterCardVue,
    permits,
    ChapterDocuments,
  },
  computed: {
    ...mapState("loader", ["loaderVisible"]),
    ...mapState("branches", ["branch"]),
    ...mapState("user", ["user"]),
  },
  data() {
    return {
      color: colors,
      tab: 0,
      loadingChapters: true,
      eventsSearchData: {
        name: "",
        page: 0,
        perPage: 12,
      },
      indicators: {},
      filtroBranch: "",
      capitulos: [],
      boolUserPermission: false,
    };
  },
  methods: {
    ...mapMutations("user", ["logout"]),
    ...mapMutations("notification", ["show"]),
    ...mapMutations("loader", ["loading", "loaded"]),
    ...mapMutations("branches", ["clearBranches"]),
    ...mapActions("branches", ["getBranches", "fetchChapterByName"]),
    ...mapActions("members", ["getMemberIndicators", "getChapterIndicators"]),

    async fetchBranches() {
      this.loadingChapters = true;
      const response = await this.getBranches();

      if (response.status === 200) {
        this.loadingChapters = false;
      }
    },

    async fetchIndicators() {
      let response = null;

      if (this.$route.params.chapterId) {
        response = await this.getChapterIndicators(
          this.$route.params.chapterId
        );
      } else {
        response = await this.getMemberIndicators();
      }

      if (response.status == true) {
        let data = response.data;
        this.indicators = {
          active: {
            color: "ACTIVE",
            members: data.activeMembers,
            submembers: data.activeSubMembers,
          },
          inactive: {
            color: "INACTIVO",
            members: data.inactiveMembers,
            submembers: data.inactiveSubMembers,
          },
          prospect: {
            color: "PROSPECTO",
            members: data.prospectMembers,
            submembers: data.prospectSubMembers,
          },
          expired: {
            color: "EXPIRED",
            members: data.expiredMembers,
            submembers: data.expiredSubMembers,
          },
        };
      }
    },

    async filterBranch() {
      this.loadingChapters = true;
      const response = await this.fetchChapterByName(this.filtroBranch);

      if (response.status === 200) {
        this.loadingChapters = false;
      }
    },
  },
  async mounted() {
    await this.fetchIndicators();
    await this.fetchBranches();
  },
};
</script>
<style scoped>
.rounded-search {
  border-radius: 0px 24px 24px 0px !important;
}

.event-container {
  min-height: 93vh;
}

.v-application .white {
  background-color: #f9fafb !important;
  border-color: #f9fafb !important;
  box-shadow: none;
}
</style>