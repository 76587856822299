<!--Tarjeta que sale en las estadisticas de "TicketsAndSales.vue" (Se usa en el componenete analytic-list.vue)-->
<template>
  <v-row class="my-5">
      <v-col cols="12" md="3" class="mb-3" v-for="item,i in getContent" :key="i">
          <v-card class="rounded-xl" elevation="0" :color="homologateStatusWithColor(item.color).color" rounded>
                  <v-row class="py-3 px-7" justify="center" align="center">
                      <v-col cols="12" class="d-flex justify-space-between align-center py-0">
                          <span class="font-weight-bold" style="font-size: 20px;">
                              Miembros <br v-if="$vuetify.breakpoint.width < 1763 && $vuetify.breakpoint.width > 955"> {{ title[item.color]}}
                          </span>
                          <h3 class="accent--text headline font-weight-bold">{{ item.members }}</h3>
                      </v-col>
                      <v-col cols="12" class="d-flex justify-space-between align-center py-1" >
                          <h2 class="subtitle-1 font-weight-bold" style="font-size: 12px; color: rgb(94, 93, 93);">
                              Submiembros <br v-if="$vuetify.breakpoint.width <= 1537 && $vuetify.breakpoint.width > 955"> {{ title[item.color] }}
                          </h2>
                          <span style="font-size: 20px;" class="accent--text  font-weight-bold">{{ item.submembers }}</span>
                      </v-col>
                  </v-row>
          </v-card>
      </v-col>
  </v-row>
</template>
<script>
import { homologateStatusWithColor } from "@/common/hook/useHelper.js";
export default {
  data() {
      return {
          title: {
              ACTIVE: 'Activos',
              INACTIVO: 'Inactivos',
              PROSPECTO: 'Prospectos',
              EXPIRED: 'Expirados',
          }
      }
  },
  props:{
      indicatorsContent: { type: Object, default: (() => {})}
  },
  methods: {
    homologateStatusWithColor
  },
  computed:{
      getSize(){
          switch (this.$vuetify.breakpoint.name) {
              case 'xs': return 'font-size:1.8em'
              case 'sm': return 'font-size:1.8em'
              case 'md': return 'font-size:1.7em'
              case 'lg': return 'font-size:2em'
              case 'xl': return 'font-size:2.3em'
          }
      },

      getContent(){
          return this.indicatorsContent
      }
  }
}
</script>
<style scoped>

</style>