<template>
  <div>
    <v-tooltip top>
      <template #activator="{ on, attrs }">
        <v-btn
          v-on="on"
          v-bind="{ ...$attrs, ...attrs }"
          :color="$attrs.color || 'accent'"
          :class="$attrs.class || 'ml-5 white--text'"
          fab
          small
          @click="$emit('click', $event)"
        >
          <v-icon>
            <slot>mdi-star</slot>
          </v-icon>
        </v-btn>
      </template>
      <span>{{ tooltip }}</span>
    </v-tooltip>
  </div>
</template>
<script>
export default {
  props: ['tooltip'],
};
</script>
