var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"width":"700","persistent":""},on:{"click:outside":_vm.closeDialog},model:{value:(_vm.activator),callback:function ($$v) {_vm.activator=$$v},expression:"activator"}},[_c('v-card',{class:_vm.$vuetify.breakpoint.smAndDown ? 'pa-9' : 'pa-14',staticStyle:{"border-radius":"0px"},attrs:{"color":"white"}},[_c('v-card-text',{staticClass:"px-0"},[_c('span',{style:({
            color: '#35404c',
            fontWeight: 900,
            fontSize: '18px',
          })},[_vm._v("Lista de documentos")]),_c('v-divider',{staticClass:"mt-1",style:({
            background: '#35404c',
            border: '1px solid #35404c',
          })}),_c('v-list',[_c('v-list-item-group',_vm._l((_vm.getFilesArray),function(document,i){return _c('v-list-item',{key:i,attrs:{"dense":""}},[_c('v-list-item-content',[_c('span',{style:({
                    fontSize: '15px',
                    color: '#35404c',
                    fontWeight: '800',
                  })},[_vm._v(_vm._s(document.name))])]),_c('v-list-item-action',[_c('div',{staticClass:"d-flex justify-space-around"},[_c('v-btn',{attrs:{"small":"","fab":"","disabled":!document.value,"elevation":"0"},on:{"click":function($event){return _vm.openVisualizer(document.formattedName, true)}}},[_c('v-icon',[_vm._v(" mdi-eye-outline ")])],1),_c('v-btn',{attrs:{"small":"","fab":"","disabled":!document.value,"elevation":"0","href":document.value}},[_c('v-icon',[_vm._v("mdi-download-outline")])],1)],1),_c('v-dialog',{ref:document.formattedName,refInFor:true,attrs:{"persistent":"","fullscreen":"","hide-overlay":""}},[_c('v-card',{attrs:{"height":"100%"}},[_c('iframe',{staticStyle:{"width":"100%","height":"100%","border":"none"},attrs:{"type":"application/pdf","id":`frame_${document.formattedName}`,"src":`https://drive.google.com/viewerng/viewer?embedded=true&url=${document.value}`}})]),(_vm.$vuetify.breakpoint.smAndDown != true)?_c('v-btn',{style:({
                          position: 'absolute',
                          bottom: '50px',
                          right: '50px',
                        }),attrs:{"bottom":"","right":"","absolute":"","fab":"","color":"accent"},on:{"click":function($event){return _vm.openVisualizer(document.formattedName)}}},[_c('v-icon',[_vm._v("mdi-close")])],1):_c('v-btn',{attrs:{"large":"","bottom":""},on:{"click":function($event){return _vm.openVisualizer(document.formattedName)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1)],1)}),1)],1)],1),_c('v-card-actions',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"text-capitalize rounded-pill",staticStyle:{"font-size":"18px","font-weight":"800"},attrs:{"elevation":"0"},on:{"click":_vm.closeDialog}},[_c('v-icon',{attrs:{"color":"#35404c"}},[_vm._v("mdi-chevron-left")]),_c('span',{staticClass:"pr-2",staticStyle:{"color":"#35404c"}},[_vm._v("Volver")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }