import moment from 'moment';

export const formatExtraFields = (name) => {
  const map = {
    'á': 'a',
    'ã': 'a',
    'é': 'e',
    'í': 'i',
    'ó': 'o',
    'ú': 'u',
    'ñ': 'n',
    ' ': '_',
    '¿': '',
    '?': ''
  };
  const joinedKeys = Object.keys(map).map(key => key).join('');
  const regex = new RegExp(`[${joinedKeys}]`, 'gi');
  return name
    .toLowerCase()
    .replace(regex, match => map[match])
};

export const formatPrice = (number) => {

  if (!number) {
    number = 0
  }
  return new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
  }).format(number);
}


export const getRangePeriod = (start, end) => ({
  startDate: moment()
    .subtract(...start)
    .toDate(),
  endDate: moment()
    .endOf(end || 'day')
    .toDate()
});

export const getDefaultRangePeriod = () => {
  let year = new Date().getFullYear()

  return {
    startDate: moment(`${year}-01-01`)
      .toDate(),
    endDate: moment()
      .toDate()
  }
};

export const downloadFile = (data, type, name) => {
  //data:${type};charset=utf-8,
  const blob = new Blob([data], { type: `data:${type};charset=utf-8` });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = name;
  link.dataset.downloadUrl = [type, link.download, link.href].join(':');
  link.click();
  link.remove();
};

export const downloadLink = (url) => {
  // // const link = document.createElement('a')
  // // link.href = 'data:text/html;charset=utf-8,' + encodeURIComponent(url);
  // // link.download = 'Reference.html';
  // // link.style.display = 'none';
  // // // link.dataset.downloadUrl = ['application/pdf', link.download, link.href]
  // // link.click();
  // // link.remove();
}

export const getBearerToken = () => {
  const token = localStorage.getItem('USER_TOKEN');
  return token && `Bearer ${token}`;
};
