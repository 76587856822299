<template>
  <v-row class="mt-12 mx-6">



    <v-col cols="12">
      <v-row>
        <v-col cols="8">
          <p class="headline font-weight-bold">
            Configuración del formulario de documentos
          </p>
        </v-col>
      </v-row>
      <v-divider class="mb-10" />
      <v-row justify="center">
        <v-col v-if="extraFields.length > 0" cols="12" class="pt-0">
          <v-row
            v-for="(field, index) in extraFields"
            :key="index"
            justify="center"
          >
            <v-col cols="12" class="d-flex justify-end">
              <v-dialog
                v-if="!field.new && !field.edit"
                v-model="field.deleteDialog"
                width="300"
              >
                <template #activator="{ on, attrs }">
                  <icon-action-button
                    tooltip="Eliminar"
                    color="red"
                    v-on="on"
                    v-bind="attrs"
                  >
                    mdi-trash-can-outline
                  </icon-action-button>
                </template>
                <v-card>
                  <v-card-title class="text-h5"
                    >Eliminar campo extra</v-card-title
                  >
                  <v-divider />
                  <v-card-text class="text-justify my-5">
                    {{
                      field.new
                        ? "¿Seguro que no quiere guardar este campo?"
                        : `¿Seguro de que quiere eliminar el campo "${field.name}"?`
                    }}
                  </v-card-text>
                  <v-divider />
                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      color="grey"
                      text
                      @click="field.deleteDialog = false"
                      :disabled="
                        !user.permissions.Membership ||
                        !user.permissions.Membership.update
                      "
                    >
                      Cancelar
                    </v-btn>
                    <v-btn
                      color="red"
                      text
                      @click="deleteField(field)"
                      :disabled="
                        !user.permissions.Membership ||
                        !user.permissions.Membership.update
                      "
                    >
                      Eliminar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <icon-action-button
                v-if="field.new || field.edit"
                tooltip="Cancelar"
                color="red"
                @click="cancelAction(field)"
                :disabled="
                  !user.permissions.Membership ||
                  !user.permissions.Membership.update
                "
              >
                mdi-close
              </icon-action-button>
              <icon-action-button
                v-if="field.new || field.edit"
                :tooltip="
                  (field.new && 'Guardar') || (field.edit && 'Actualizar')
                "
                color="green"
                @click="saveField(field)"
                :disabled="
                  !user.permissions.Membership ||
                  !user.permissions.Membership.update ||
                  disableField(field)
                "
              >
                mdi-content-save-outline
              </icon-action-button>
              <icon-action-button
                v-if="!field.new && !field.edit"
                tooltip="Editar"
                color="accent"
                @click="editField(field)"
                :disabled="
                  !user.permissions.Membership ||
                  !user.permissions.Membership.update
                "
              >
                mdi-pencil-outline
              </icon-action-button>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3">
              <p class="font-weight-bold">Opciones</p>
              <v-switch
                v-model="field.required"
                class="ml-2"
                color="primary"
                label="¿Obligatorio?"
                hide-details="auto"
                :disabled="!field.edit && !field.new"
              ></v-switch>
            </v-col>

            <v-col cols="12" sm="6" md="3" lg="4">
              <p class="font-weight-bold">Configuración</p>
              <v-text-field
                label="Nombre del campo o pregunta"
                v-model="field.name"
                filled
                rounded
                :disabled="!field.edit && !field.new"
              ></v-text-field>
              <v-text-field
                label="Descripción"
                v-model="field.description"
                filled
                rounded
                :disabled="!field.edit && !field.new"
              ></v-text-field>
              <v-combobox
                v-model="field.options"
                v-if="field.fieldType === 'LIST'"
                hide-selected
                hint="Agrega las opciones de tu campo."
                label="Opciones del campo"
                multiple
                persistent-hint
                small-chips
                filled
                rounded
                :disabled="!field.edit && !field.new"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        Presiona
                        <kbd>enter</kbd> para crear una nueva opción.
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-combobox>
              <v-combobox
                v-if="field.fieldType === 'DOC'"
                v-model="field.accept"
                :items="fileExtensions"
                item-text="text"
                
                hide-selected
                hint="Agrega los formatos permititdos para este campo. O agrega el que necesites ej: .pdf, .docx, .jpg"
                label="Formatos permitidos del campo"
                multiple
                persistent-hint
                small-chips
                filled
                rounded
                :disabled="!field.edit && !field.new"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        Presiona
                        <kbd>enter</kbd> para agregar el formato.
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-combobox>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="6" md="4">
              <p class="font-weight-bold">Vista previa</p>
              <FormInput :index="index" :field="field" @input="inputHandler" />
            </v-col>
            <v-col cols="12" class="my-5">
              <v-divider />
            </v-col>
          </v-row>
        </v-col>
        <h3 v-else class="my-10">No se ha creado ningún campo extra</h3>
      </v-row>
      <v-row
        class="pb-7"
        v-if="
          user.permissions.Membership &&
          user.permissions.Membership.update == true
        "
      >
        <v-col
          cols="12"
          :class="
            $vuetify.breakpoint.mdAndUp
              ? 'd-flex justify-end'
              : 'd-flex justify-center'
          "
        >
          <action-button @click="addField" iconLeft icon="mdi-plus">
            Nuevo campo
          </action-button>
        </v-col>
      </v-row>
    </v-col>

    <v-dialog fullscreen hide-overlay transition="dialog-bottom-transition" :value="prevDataSheet||prevCredential">
      <template v-slot:default="dialog">
        <v-btn
          v-if="$vuetify.breakpoint.smAndDown != true"
          @click="dialog.value = false, closePrev()"
          bottom
          right
          absolute
          fab
          color="accent"
          :style="{
            position: 'absolute',
            bottom: '50px',
            right: '50px',
          }"
          ><v-icon>mdi-close</v-icon></v-btn
        >
        <v-btn v-else @click="dialog.value = false, closePrev()" large bottom
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </template>
    </v-dialog>
  </v-row>
</template>
<script>
import qs from "query-string";
import { mapMutations, mapActions, mapState } from "vuex";
import FormInput from "@/components/shared/FormInput.vue";
import ActionButton from "@/components/shared/ActionButton.vue";
import ModalTemplate from "@/components/shared/ModalTemplate";
import IconActionButton from "@/components/shared/IconActionButton.vue";
import Endpoints from "@/share/Endpoints";
import { formatExtraFields } from "@/utils";
import Vue from "vue";

export default {
  components: {
    ActionButton,
    IconActionButton,
    ModalTemplate,
    FormInput,
  },
  props: ["affiliationId"],
  data: () => ({
    prevDataSheet: false,
    prevCredential: false,
    affiliationLevel: {
      items: [],
      selected: null,
    },
    extraFields: [],
    fileExtensions: [
      {
        text: "PDF",
        value: ".pdf",
      },
      {
        text: "Word",
        value: ".docx",
      },
      {
        text: "Imagen",
        value: ".jpg, .jpeg, .png, .gif, .bmp, .svg",
      },
      {
        text: "Excel",
        value: ".xlsx",
      },
      {
        text: "Power Point",
        value: ".pptx",
      },
    ],
  }),
  async created() {
    const response = await this.getBranchesDocs();
    if (response.status) {
     this.extraFields = response.data.data
    }
  },
  computed: {
    ...mapState("user", ["user"]),
  },
  methods: {
    ...mapMutations("notification", ["show"]),
    ...mapActions("registrationFields", [
      "getRegistrationFields",
      "addRegistrationField",
      "getBranchesDocs",
      "postBranchesDocs",
      "putBranchesDocs",
      "updateRegistrationField",
      "deleteRegistrationField",
    ]),
    closePrev() {
      this.prevDataSheet = false;
      this.prevCredential = false;
    },
    inputHandler({ index, value }) {
      console.log(index, value);
    },
    formatAccept(accept) {
      if (!accept) return "";
      let acceptString = "";
      accept.forEach((item) => {
        acceptString += item.value + ", ";
      });
      return acceptString;
    },
    async createField(field) {
      delete field.new;
      delete field.deleteDialog
      const { _id: temporaryId, ...restFieldProps } = field;
      const payload = {
        ...restFieldProps,
        formattedName: formatExtraFields(field.name),
      };
      const response = await this.postBranchesDocs(payload);
      if (response.status) {
        const { documentInput } = response.data;
        this.extraFields = this.extraFields.map((ticket) =>
          ticket._id === temporaryId
            ? {
                ...restFieldProps,
                ...documentInput,
                edit: false,
                id: documentInput._id,
                deleteDialog: false
              }
            : ticket
        );
        this.show({
          color: "primary",
          text: "Campo creado",
        });
      } else {
        this.show({
          color: "error",
          text: "Error al crear el campo",
        });
      }
    },
    async updateField(field) {
      delete field.edit
      const {...restFieldProps } = field;
      const payload = {
        ...restFieldProps,
        formattedName: formatExtraFields(field.name),
      };

      console.log(payload);
      const res = await this.putBranchesDocs(payload);
      if (res.status) {
        for (let i = 0; i < this.extraFields.length; i++) {
          if (this.extraFields[i]._id === field._id) {
            Vue.set(this.extraFields, i, {
              ...field,
              edit: false,
            });
          }
        }
        this.show({
          color: "primary",
          text: "Campo actualizado",
        });
      } else {
        this.show({
          color: "error",
          text: "Error al actualizar el campo",
        });
      }
    },
    saveField(field) {
      field?.edit && this.updateField(field);
      field?.new && this.createField(field);
    },
    editField(field) {
      Vue.set(field, "edit", true);
      field.originalData = { ...field };
      console.log(field);
    },
    cancelEdition(field) {
      this.extraFields = this.extraFields.map((auxField) =>
        auxField._id === field._id
          ? { ...field.originalData, edit: false }
          : { ...auxField }
      );
    },
    cancelAction(field) {
      field?.edit && this.cancelEdition(field);
      field?.new && this.removeField(field._id);
    },
    addField() {
      const field = {
        _id: new Date().getTime(),
        organization: this.user._id,
        fieldType: "DOC",
        required: true,
        name: "",
        formattedName: "",
        description: "",
        accept: [],
        value: "",
        new: true,
        permissions: ["HEAD_OFFICE", "BRANCH"],
        deleteDialog: false 
      };
      this.extraFields.push(field);
    },
    async deleteField(field) {
      const newField = field?.new;
      const fieldId = field._id
      if (!newField) {
        await this.axios.delete(
          `/registrationFields/form/branches/docs/${fieldId}`
        );
        this.show({
          color: "primary",
          text: "Campo eliminado correctamente",
        });
      }
      this.removeField(fieldId);
      field.deleteDialog = false;
    },
    removeField(fieldId) {
      this.extraFields = this.extraFields.filter(
        (field) => field._id !== fieldId
      );
    },
    disableField(field) {
      const { originalData, ...rest } = field;
      const isFormWithoutChanges =
        JSON.stringify(originalData) === JSON.stringify(rest);
      return isFormWithoutChanges;
    },
  },
};
</script>