<template>
  <v-card class="my-12 cardStyle" elevation="2" max-width="374">
    <template slot="progress">
      <v-progress-linear color="deep-purple" height="10" indeterminate></v-progress-linear>
    </template>

    <v-img @click="openBranch()" height="150" :src="getBanner">
      <v-icon :color="getChapterStatus(chapter).color" size="30" class="status-icon">
        {{ getChapterStatus(chapter).icon }}
      </v-icon>
    </v-img>

    <v-card-title class="titleCard pt-2 pb-2" @click="openBranch()">{{
      chapter ? chapter.company : ""
    }}
    </v-card-title>

    <v-row align="center" justify="space-around" class="mx-0">
      <v-col cols="3" class="d-flex justify-center">
        <v-icon color="#35404c"> {{ icons.mdiAccountCheck }} </v-icon>
        <span class="ml-2 blueText">{{ chapter ? chapter.activeMembers : '' }}</span>
      </v-col>
      <v-col cols="3" class="d-flex justify-center">
        <v-icon color="#35404c"> {{ icons.mdiAccountMinus }} </v-icon>
        <span class="ml-2 blueText">{{ chapter ? chapter.inacvtiveMembers : '' }}</span>
      </v-col>
      <v-col cols="3" class="d-flex justify-center">
        <v-icon color="#35404c"> {{ icons.mdiAccountMultiple }} </v-icon>
        <span class="ml-2 blueText">{{ chapter ? chapter.totalMembers : '' }}</span>
      </v-col>
      <v-col cols="3" class="d-flex justify-center">
        <v-icon color="#35404c" @click="openEditBranchNameDialog()"> {{ icons.mdiPencil }} </v-icon>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center">
      <v-col cols="10" class="d-flex justify-center">
        <v-btn color="#b3e5fc" elevation="0" block class="text-capitalize cardStyle" @click="dialogActivator = true"
          style="font-size: 17px; font-weight: 800">
          <v-icon class="mx-2"> mdi-folder-outline</v-icon>
          Documentos
        </v-btn>
      </v-col>

    </v-row>

    <DocumentsDialogGlobal :activator="dialogActivator" @close="dialogActivator = false" @download="downloadFiles"
      :files="chapter ? chapter.branchDocForm : []">
    </DocumentsDialogGlobal>

    <v-dialog v-model="editBranchNameDialog" persistent max-width="600px">

      <v-card>
        <v-card-title>
          <span class="text-h5">Editar nombre de capítulo</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>

              <v-col cols="12">
                <v-text-field label="Nombre del capítulo*" required v-model="updatedBranchName"></v-text-field>
              </v-col>

            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="editBranchNameDialog = false">
            Cancelar
          </v-btn>
          <v-btn color="blue darken-1" text @click="editBranchName()"
            :disabled='getEditBranchNameConfirmationButtonEstatus'>
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import { mapMutations, mapActions } from "vuex";
import { mdiAccountMultiple, mdiAccountMinus, mdiAccountCheck, mdiPencil } from "@mdi/js";
import DocumentsDialogGlobal from "../../shared/DocumentsDialogGlobal.vue";
import { downloadFile } from "@/utils";
export default {
  props: {
    chapter: { type: Object, default: () => { } },
  },
  components: {
    DocumentsDialogGlobal,
  },
  data() {
    return {
      dialog: false,
      editBranchNameDialog: false,
      updatedBranchName: "",
      icons: {
        mdiAccountMultiple,
        mdiAccountMinus,
        mdiAccountCheck,
        mdiPencil
      },
    };
  },
  computed: {
    getBanner() {
      const chapter = new Object(this.chapter);
      return Object.keys(chapter).length > 0 &&
        chapter.branchAffiliation !== null &&
        chapter.branchAffiliation.hasOwnProperty("banner")
        ? chapter.branchAffiliation.banner
        : "https://wechamber-v3-files.s3.us-east-2.amazonaws.com/withoutbanner.png";
    },
    dialogActivator: {
      get() {
        return this.dialog;
      },
      set(value = false) {
        this.dialog = value;
      },
    },
    getEditBranchNameConfirmationButtonEstatus: function () {
      if (this.updatedBranchName) {
        return false
      }
      return true
    }
  },
  methods: {
    ...mapMutations("notification", ["show"]),
    ...mapActions("branches", ["getBranches", "fetchChapterByName", "setBranchName"]),
    openBranch() {
      console.log(this.chapter._id);
      this.$router.push(`/mi-capitulo/miembros/${this.chapter._id}`);
    },
    openEditBranchNameDialog() {
      this.editBranchNameDialog = true
    },
    getChapterStatus(chapter) {
      return {
        icon: chapter?.validated ? "mdi-check-decagram" : "mdi-alert-decagram",
        color: chapter?.validated ? "#87ee61" : "#ffbb14",
      };
    },
    downloadFiles(file) {
      console.log(file);
    },
    async editBranchName() {
      try {
        await this.setBranchName({
          branch: this.chapter._id,
          name: this.updatedBranchName,
        })
        this.editBranchNameDialog = false
        await this.getBranches();
      } catch (error) {
        console.log(error);
      }
    }
    // editEvent(){
    //   this.$router.push(`/mi-evento/micrositio/${this.event._id}`);
    // },
    // openDialogToDeleteEvent(eventSelectedId) {
    //   this.$emit('openDialogToDeleteEvent', eventSelectedId);
    // },
    // /**
    //  * Función que devuelve un link para compartir el evento seleccionado.
    //  * @param { string } id Identificador único de un evento.
    //  */
    // shareItem(id) {
    //   return `${window.location.origin}/micrositio/${id}`;
    // },
    // formatDate(date) {
    //   let [year, month, day] = date.substring(0, 10).split("-");
    //   return `${day}-${month}-${year}`;
    // },
    // isValidDate(date) {
    //   let today = new Date();
    //   let currentDate = new Date(date);
    //   if (today > currentDate) {
    //     return false;
    //   } else {
    //     return true;
    //   }
    // },
    // onCopy: function (e) {
    //   this.show({
    //     text: "¡Se ha copiado la url del evento al portapapeles!",
    //     color: "primary"
    //   });
    // },
    // onError: function (e) {
    //   this.show({
    //     text: "¡Ha ocurrido un problema al copiar la url del evento!",
    //     color: "error"
    //   });
    // }
  },
};
</script>
<style scoped>
.event-card {
  transition: all 0.8s;
}

.event-card:hover {
  transform: scale(1.05);
}

.grayscale-disabled-element {
  filter: grayscale(1);
}

.titleCard {
  color: #35404c !important;
  font-weight: bold !important;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 18px;
  word-break: break-word !important;
}

.blueText {
  color: #35404c !important;
}

.status-icon {
  border-radius: 50px;
  border: 2px solid white;
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: white;
}

.cardStyle {
  border-radius: 25px;
}
</style>